import React, { CSSProperties, useEffect, useState } from "react";
import "./App.css";
import Receipt from "./components/Receipt";
import { BarLoader } from "react-spinners";
import logoWithHubble from "./header_logo.png";
import logoGeneric from "./invoice_generic_header.png";
import Spacer from "./components/util/Spacer";
import axios from "axios";
import { TransactionReceiptDetails } from "./types";

function App() {
  const href = window.location.href;
  const splits = href.split("/");
  const id = splits[splits.length - 1];
  let baseUrl = "https://myawsalb.mygullak.money";

  if (process.env.REACT_APP_HUBBLE_BASE_URL) {
    baseUrl = process.env.REACT_APP_HUBBLE_BASE_URL;
  }
  const path = `public/redemptions/receipt`;

  const [loading, setLoading] = useState(true);
  const [receiptDetails, setReceiptDetails] =
    useState<TransactionReceiptDetails | null>(null);

  const getData = async () => {
    const data = await axios.get(`${baseUrl}/${path}/${id}`, {
      withCredentials: false,
      responseType: "json",
      responseEncoding: "utf8",
    });
    setLoading(false);
    setReceiptDetails(data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const override: CSSProperties = {};
  return (
    <div className="App">
      {receiptDetails && receiptDetails.clientId == "hubble" ? (
        <React.Fragment>
          <div className="Header">
            <img src={logoWithHubble} alt={"Header logo"} />
          </div>
          <Spacer height={24} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="Header">
            <img src={logoGeneric} alt={"Header logo"} />
          </div>
          <Spacer height={24} />
        </React.Fragment>
      )}

      {loading ? (
        <div className="LoaderWrapper">
          <BarLoader
            className="Loader"
            color="#7c7c7c"
            loading={loading}
            width={60}
            cssOverride={override}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <Receipt receipt={receiptDetails!} />
      )}
    </div>
  );
}

export default App;
