import React from "react";
import hubble_logo from "../hubble_logo.png";
import tick_icon from "../tick_icon.png";
import "./Receipt.css";
import Spacer from "./util/Spacer";
import Line from "./util/Line";
import { TransactionReceiptDetails } from "../types";

type ReceiptProps = {
  receipt: TransactionReceiptDetails;
};

function capitalize(str: String) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const format = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});

function formatAmount(amount: number): string {
  return format.format(amount);
}

function getHubbleDiscount(receipt: TransactionReceiptDetails): number {
  return receipt.walletDiscount;
}

function getCouponDiscount(receipt: TransactionReceiptDetails): number {
  return receipt.couponDiscount;
}

function getPaidAmount(receipt: TransactionReceiptDetails): number {
  return receipt.paidAmount;
}

function formatDate(date: Date): string {
  return date.toLocaleDateString() + " " + date.toLocaleTimeString("en-IN");
}

function Receipt(props: ReceiptProps) {
  return (
    <div className="Receipt FlexColumn">
      <div className="Intro">
        <div className="Section1 GreyOut TextSize13">
          <span>{formatDate(new Date(props.receipt.transactedAt))}</span>
          <span>Order no. {props.receipt.id}</span>
          <span>Payment id. {props.receipt.providerPaymentId}</span>
        </div>

        <Spacer height={8} />

        <div className="Section2 W600">
          Hi {capitalize(props.receipt.userName)},
        </div>

        <Spacer height={8} />

        <div className="Section3">
          Thanks for purchasing {capitalize(props.receipt.brandName)} card worth
          {` `}
          {formatAmount(props.receipt.amount)}
          {props.receipt.clientId == "hubble" ? " from Hubble" : "."}
        </div>
      </div>

      <SectionDivider />

      <div className="ItemDetails">
        <div className="Heading">Item details</div>
        <Spacer height={12} />
        <Line background={"#EDEEF1"} height={1} />
        <div className="Table">
          <div className="LineItem LineItemHeader">
            <span>Item</span>
            <span>No.</span>
          </div>
          {props.receipt.denominationDetails.map((denomination) => {
            return (
              <React.Fragment>
                <Spacer height={8} />
                <div className="LineItem">
                  <span>
                    {formatAmount(denomination.denomination)}{" "}
                    {capitalize(props.receipt.brandName)} Brand Card
                  </span>
                  <span>x {denomination.quantity}</span>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>

      <SectionDivider />

      <div className="BillDetails FlexColumn">
        <div className="Heading">Bill details</div>
        <Spacer height={12} />
        <div className="Table">
          <div className="LineItem CardAmount">
            <span>Brand card</span>
            <span>{formatAmount(props.receipt.amount)}</span>
          </div>
          <Spacer height={8} />
          {props.receipt.rewardType === "PREPAID" ? (
            <div className="LineItem LineItemDiscount HubbleDiscount">
              <span>
                {props.receipt.clientId == "hubble"
                  ? "Hubble Discount"
                  : "Discount"}
              </span>
              <span>- {formatAmount(getHubbleDiscount(props.receipt))}</span>
            </div>
          ) : (
            <div className="LineItem CardAmount">
              <span>
                {props.receipt.clientId == "hubble"
                  ? "Hubble Discount"
                  : "Discount"}
              </span>
              <span>{formatAmount(getHubbleDiscount(props.receipt))}</span>
            </div>
          )}
          <Spacer height={8} />
          <div className="LineItem LineItemDiscount CouponDiscount">
            <span>Coupon discount</span>
            <span>- {formatAmount(getCouponDiscount(props.receipt))}</span>
          </div>
        </div>
        <Spacer height={12} />
        <Line background={"#EDEEF1"} height={1} />
        <div className="Table">
          <div className="LineItem YouPaid">
            <span>
              You Paid {props.receipt.taxDetails && " (incl. of tax)"}
            </span>
            <span>{formatAmount(getPaidAmount(props.receipt))}</span>
          </div>
        </div>
      </div>

      {props.receipt.clientId == "hubble" ? (
        <React.Fragment>
          <SectionDivider />
          <div className="CompanyDetails FlexColumn">
            <img src={hubble_logo} height={16} width={87} alt={"hubble logo"} />
            <Spacer height={8} />
            <div className="Name W600">Gullak technologies private limited</div>
            <Spacer height={8} />
            <div className="Address GreyOut TextSize13">
              Hustle Hub 1703, 17th Cross, Sector 4, HSR Layout, Bengaluru,
              Karnataka - 560102.
            </div>
            <div className="Disclaimer GreyOut TextSize13">
              <br />
              The above value includes 0% platform fee
            </div>
          </div>
        </React.Fragment>
      ) : null}

      <SectionDivider />
      <div className="Tnc FlexColumn">
        <div className="Heading">Terms & Conditions</div>
        <Spacer height={12} />
        {props.receipt.tnc.map((pointer) => {
          return (
            <div className="TncListItem GreyOut TextSize13">
              <span>
                <img src={tick_icon} alt={"tick-icon"} width={20} height={20} />
              </span>
              <div className="TncListItemMessage">
                <span>{pointer}</span>
              </div>
            </div>
          );
        })}
      </div>

      {props.receipt.clientId != "hubble" ? (
        <React.Fragment>
          <SectionDivider />
          <div className="PoweredBy FlexRow">
            Powered by
            <Spacer width={4} />
            <img src={hubble_logo} height={16} width={87} alt={"hubble logo"} />
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}

function SectionDivider() {
  return (
    <React.Fragment>
      <Spacer height={24} />

      <Line background={"#EDEEF1"} height={2} />

      <Spacer height={24} />
    </React.Fragment>
  );
}

export default Receipt;
