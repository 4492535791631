import React from 'react';

type SpacerProps = {
    height: number;
} | {
    width: number;
}

function Spacer(props: SpacerProps) {
    if (props.hasOwnProperty('height')) {
        const height = (props as {height: number}).height
        return (
            <span style={{height: height}}/>
        )
    }

    const width = (props as {width: number}).width
    return (
        <span style={{width: width}}/>
    )
}

export default Spacer;
