import React from "react";

type LineProps = {
  background: string;
  height: number;
};

function Line(props: LineProps) {
  return (
    <hr
      style={{
        width: "100%",
        background: props.background,
        color: props.background,
        border: "none",
        height: `${props.height}px`,
      }}
    />
  );
}

export default Line;
